import React from "react";

import styled, { keyframes } from "styled-components";
import colors from "src/assets/styles/colors.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const move = keyframes`
   0%, 100% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-100%);
  }
  77% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-200%);
  }
`;
const ButtonWrapper = styled(ScrollLink)`
  display: inline-block;
  width: 160rem;
  position: relative;
  height: 50rem;
  text-align: center;
  font-size: ${typographySizes.s}rem;
  line-height: 50rem;
  font-weight: 300;
  color: ${colors.dark};
  border: solid 1rem ${colors.dark};
  border-radius: 50rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  :hover {
    span {
      animation: ${move} 2s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const Button = ({ children, to, spy, smooth, offset, duration }) => (
  <ButtonWrapper
    to={to}
    spy={spy}
    smooth={smooth}
    offset={offset}
    duration={duration}
    data-sal="slide-up"
    data-sal-easing="ease"
    data-sal-delay="400"
    data-sal-duration="500"
  >
    <span>{children}</span>
    <span> {children}</span>
  </ButtonWrapper>
);

export default Button;
