import React from "react";
import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const StyledWrapper = styled(MainWrapper)`
  height: calc((100vw - ${distances.pageMarginBig * 2}rem) / 1.7777777);
  position: relative;
  /* margin: 100rem 0; */
  margin-bottom: 100rem;
  overflow: hidden;
  border-radius: 8rem;
  @media (max-width: ${mediaQuery.laptop}) {
    height: calc((100vw - ${distances.pageMarginBig}rem) / 1.7777777);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: calc((100vw - 30rem) / 1.7777777);
    margin-bottom: 40rem;
  }
  video {
    position: absolute;

    left: 0;

    width: 100%;
    /* width: 177.77777778vh; 
    min-width: 100%;
    min-height: 56.25vw; */
    position: absolute;
    left: 50%; /* % of surrounding element */
    top: calc(50% - 1rem);
    transform: translate(-50%, -50%); /* % of current element */
    z-index: 1;
  }
`;

const Video = (vid) => (
  <StyledWrapper>
    <video autoPlay muted loop playsInline>
      <source src={vid.vid} type="video/mp4" />
    </video>
  </StyledWrapper>
);

export default Video;
