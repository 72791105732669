import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";
import Title from "src/components/Typography/Title.js";
import Body from "src/components/Typography/Body.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import noHangers from "src/assets/fn/noHangers.js";

import ArrowSlider from "src/components/Variant/InfoBox/ArrowSlider.js";

const Wrapper = styled.div`
  grid-area: sliderImg;
  background-color: ${colors.white};
  border-radius: 8rem;
  overflow: hidden;
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  filter: drop-shadow(0rem 0rem 20rem rgba(0, 0, 0, 0.08));
  display: flex;
  @media (max-width: ${mediaQuery.laptop}) {
    height: 50vw;
  }
  opacity: 0;
  transform: translateY(20rem);
  &.show {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.5s 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const ImgWrapper = styled.div`
  /* position: absolute !important; */
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const StyledImg = styled(Img)`
  position: absolute !important;
  height: 101%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const NavWrapper = styled.div`
  position: absolute;
  bottom: 20rem;
  left: 20rem;
  width: calc(100% - 40rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.p`
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  text-align: center;
  margin: 0 10rem;
`;

const SliderImg = ({ slider, show }) => {
  const [cur, setCur] = useState(0);

  const handleClickLeft = (num) => {
    if (cur <= 0) {
      setCur(slider.length - 1);
    } else {
      setCur(cur - num);
    }
  };
  const handleClick = (num) => {
    console.log(cur);
    if (cur >= slider.length - 1) {
      setCur(0);
    } else {
      setCur(cur + num);
    }
  };
  useEffect(() => {
    setCur(0);
  }, [slider]);
  return (
    <Wrapper className={show && "show"}>
      {slider.map((pos) => (
        <ImgWrapper style={{ transform: `translateX(${cur * -100}%)` }}>
          <StyledImg
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={pos.Img.localFile.childImageSharp.fluid}
          />
        </ImgWrapper>
      ))}

      <NavWrapper>
        <ArrowSlider fnClick={handleClickLeft} left />
        <Text>{noHangers(slider[cur].Text)}</Text>
        <ArrowSlider fnClick={handleClick} />
      </NavWrapper>
    </Wrapper>
  );
};

export default SliderImg;
