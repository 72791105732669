import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";

const Title = styled.p`
  font-size: ${typographySizes.s}rem;
  font-weight: 100;
  color: ${colors.dark};
  line-height: 1.3;
`;

export default Title;
