import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const Title = styled.h2`
  font-size: ${typographySizes.m}rem;
  font-weight: 500;
  color: ${colors.dark};
  line-height: 1.2;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.mM}rem;
  }
`;

export default Title;
