import React, { useState } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import Pl from "src/assets/svg/pl.svg";
import En from "src/assets/svg/en.svg";
import De from "src/assets/svg/de.svg";

import Arrow from "src/assets/svg/nav-arrow.inline.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30rem;
  margin-left: 30rem;
  font-size: 20rem;
  position: relative;
  cursor: pointer;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-right: 20rem;
  }
`;

const FlagWrapper = styled.div`
  height: 20rem;
  width: 20rem;
  border-radius: 18rem;
  overflow: hidden;
  box-sizing: border-box;
  border: solid 1rem ${colors.dark};
  margin-right: 5rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  position: relative;
  :hover {
    transform: translateY(-3rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 130% !important;
  }
`;

const DropDwon = styled.div`
  position: absolute;
  transform: translate(-50%, calc(100% - 10rem));
  bottom: -20rem;
  left: 50%;
  background-color: ${colors.white};
  padding: 10rem;
  border-radius: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.open {
    transform: translate(-50%, 100%);
    opacity: 1;
    pointer-events: inherit;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ${FlagWrapper} {
    margin-right: 0rem;
  }
`;

const StyledLink = styled(Link)`
  margin-bottom: 10rem;
  :last-of-type {
    margin-bottom: 0;
  }
`;

const StyledArrow = styled(Arrow)`
  transform: rotate(0deg);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.open {
    transform: rotate(180deg);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const LangButton = ({ lang }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(open ? false : true);
  };
  return (
    <Wrapper onClick={handleClick}>
      <FlagWrapper>
        {lang == "pl" && <img src={Pl} />}
        {lang == "en" && <img src={En} />}
        {lang == "de" && <img src={De} />}
      </FlagWrapper>

      <StyledArrow className={open && "open"} />
      <DropDwon className={open && "open"}>
        {lang !== "pl" && (
          <StyledLink to="/">
            <FlagWrapper>
              {" "}
              <img src={Pl} />
            </FlagWrapper>
          </StyledLink>
        )}
        {lang !== "en" && (
          <StyledLink to="/en">
            <FlagWrapper>
              <img src={En} />
            </FlagWrapper>
          </StyledLink>
        )}
        {lang !== "de" && (
          <StyledLink to="/de">
            <FlagWrapper>
              <img src={De} />
            </FlagWrapper>
          </StyledLink>
        )}
      </DropDwon>
    </Wrapper>
  );
};

export default LangButton;
