import * as React from "react";
import { graphql } from "gatsby";

import Nav from "src/components/Global/Nav/Nav.js";
import Header from "src/components/Header/Header.js";
import FeaturesShort from "src/components/FeaturesShort/FeaturesShort.js";
import Colors from "src/components/Colors/Colors.js";
import About from "src/components/About/About.js";
import Feature from "src/components/Feature/Feature.js";
import Variants from "src/components/Variant/Variants.js";
import Video from "src/components/Video/Video.js";
import Footer from "src/components/Footer/Footer.js";
import GetInspired from "src/components/GetInspired/GetInspired.js";
import SEO from "src/components/Global/SEO.js";

// markup
const Home = ({ data }) => {
  return (
    <main>
      <SEO
        title={data.strapiHomePage.SEO.Title}
        description={data.strapiHomePage.SEO.Description}
        image={data.strapiHomePage.SEO.Img.localFile.publicURL}
        lang={data.strapiHomePage.locale}
      />
      <Nav
        lang={data.strapiHomePage.locale}
        logo={data.strapiNavigation.Logo.localFile.childImageSharp.fluid}
        variants={data.strapiNavigation.Variants}
        features={data.strapiNavigation.Features}
        inspirations={data.strapiNavigation.Inspirations}
        buyLink={data.strapiNavigation.Buy_link}
        buyName={data.strapiNavigation.Buy_name}
        labelCreatorLink={data.strapiNavigation.Label_creator_link}
        labelCreatorName={data.strapiNavigation.Label_creator_name}
        imgMobile={
          data.strapiNavigation.Mobile_img.localFile.childImageSharp.fluid
        }
      />
      <Header
        title={data.strapiHomePage.Header_title}
        description={data.strapiHomePage.Header_description}
        button={data.strapiHomePage.Header_button}
        firstImg={
          data.strapiHomePage.Header_first_binder.localFile.childImageSharp
            .fluid
        }
        secondImg={
          data.strapiHomePage.Header_second_binder.localFile.childImageSharp
            .fluid
        }
      />
      <FeaturesShort
        title={data.strapiHomePage.Features_short_title}
        description={data.strapiHomePage.Features_short_paragraph}
        tileVideo={data.strapiHomePage.Features_short_tile_video}
        tileNum={data.strapiHomePage.Features_short_tile_number}
      />
      <Colors
        title={data.strapiHomePage.Colors_title}
        colors={data.strapiHomePage.Colors_color}
        tiles={data.strapiHomePage.Colors_Tile}
      />
      <About
        title={data.strapiHomePage.About_title}
        paragraph={data.strapiHomePage.About_paragraph}
        img1col={data.strapiHomePage.About_first_row_img}
        img2col={data.strapiHomePage.About_second_row_img}
        buttonName={data.strapiHomePage.About_button_name}
        buttonLink={data.strapiHomePage.About_button_link}
      />
      <Feature
        title={data.strapiHomePage.Features_title}
        posF={data.strapiHomePage.Feature}
      />
      <Variants
        variants={data.allStrapiVariant.edges}
        firstCol={data.strapiHomePage.Variants__first_col_title}
        secondCol={data.strapiHomePage.Variants__second_col_title}
        title={data.strapiHomePage.Variants_title}
      />

      <Video vid={data.strapiHomePage.Video[0].localFile.publicURL} />
      <GetInspired
        title={data.strapiHomePage.Get_inspired_title}
        place={data.strapiHomePage.Get_inspired_Place}
      />

      <Footer
        title={data.strapiFooter.Title}
        subTitle={data.strapiFooter.SubTitle}
        logo={data.strapiFooter.Logo}
        img={data.strapiFooter.Img}
        links={data.strapiFooter.Link}
      />
    </main>
  );
};

export default Home;

export const IndexQuery = graphql`
  query Home($locale: String!) {
    allStrapiVariant(
      sort: { order: ASC, fields: Size }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          Type
          Size
          Name
          Description
          Thumbnail_img {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          Top_colors {
            Color_Hex
            Color_name
            Binder {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          Open_top_binder_v1 {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          Open_top_binder_v2 {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          Top_slider {
            Img {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            Text
          }
          Dimensions {
            Details
            Name
          }
          Product_features {
            Details
            Name
          }
          Additional_information {
            Text
          }
          Information_signs {
            Img {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          Product_list {
            Color_hex
            Name
          }
        }
      }
    }
    strapiHomePage(locale: { eq: $locale }) {
      SEO {
        Description
        Title
        Img {
          localFile {
            publicURL
          }
        }
      }
      locale
      Header_title
      Header_description
      Header_button
      Header_first_binder {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Header_second_binder {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Colors_title
      Colors_color {
        Color_Hex
        Binder {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      Colors_Tile {
        Description
        Title
      }
      About_title
      About_button_link
      About_button_name
      About_paragraph
      About_first_row_img {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              aspectRatio
            }
          }
        }
      }
      About_second_row_img {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              aspectRatio
            }
          }
        }
      }
      Features_title
      Feature {
        Description
        Title
        Animation_in {
          localFile {
            publicURL
          }
        }
        Animation_out {
          localFile {
            publicURL
          }
        }
      }

      Features_short_title
      Features_short_paragraph
      Features_short_tile_number {
        Number
        Text
      }
      Features_short_tile_video {
        Text
        Video {
          localFile {
            publicURL
          }
        }
      }
      Video {
        localFile {
          publicURL
        }
      }
      Variants_title
      Variants__first_col_title
      Variants__second_col_title

      Get_inspired_title
      Get_inspired_Place {
        Quote
        Quote_person
        Name
        Background_color
        Text_color
        Img_1 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        Img_2 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        Img_3 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        Img_4 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    strapiNavigation(locale: { eq: $locale }) {
      Buy_link
      Features
      Buy_name
      Inspirations
      Label_creator_link
      Label_creator_name
      Variants
      Mobile_img {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Logo {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    strapiFooter(locale: { eq: $locale }) {
      SubTitle
      Title
      Logo {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Img {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Link {
        Link
        Name
      }
    }
  }
`;
