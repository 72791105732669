import React from "react";
import styled, { keyframes } from "styled-components";
import Arrow from "src/assets/svg/features-arrow.inline.svg";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const StyledArrow = styled(Arrow)``;

const move = keyframes`
  0% {
    transform: translateX(0) ;
  }

  50% {
    transform: translateX(35rem);
  }
  51% {
    transform: translateX(-35rem);
  }
  100% {
    transform: translateX(0);
  }
`;

const moveL = keyframes`
  0% {
    transform: translateX(0) rotate(180deg);
  }

  50% {
    transform: translateX(-35rem) rotate(180deg);
  }
  51% {
    transform: translateX(35rem) rotate(180deg);
  }
  100% {
    transform: translateX(0) rotate(180deg);
  }
`;
const Next = styled.div`
  width: 55rem;
  height: 55rem;
  border: solid 1rem ${colors.dark};
  border-radius: 55rem;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  &.hover {
    ${StyledArrow} {
      animation: ${move} 0.5s;
    }
  }
  @media (max-width: ${mediaQuery.laptop}) {
    /* display: none; */
  }
`;

const ArrowButtonThumb = ({ hover }) => {
  return (
    <Next className={hover && "hover"}>
      {" "}
      <StyledArrow />{" "}
    </Next>
  );
};

export default ArrowButtonThumb;
