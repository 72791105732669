import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Img from "gatsby-image";
import Tilt from "react-parallax-tilt";

import MainWrapper from "src/components/global/MainWrapper.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import noHangers from "src/assets/fn/noHangers.js";

import Title from "src/components/Typography/Title.js";

const StyledMainWrapper = styled(MainWrapper)`
  margin-top: 100rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100rem;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-bottom: 40rem;
    margin-top: 40rem;
  }
`;

const StyledTitle = styled(Title)`
  width: 70%;

  text-align: center;
  margin-bottom: 40rem;
`;

const Nav = styled.div`
  display: flex;
  margin-bottom: 40rem;
  flex-wrap: wrap;
  justify-content: center;
`;
const move = keyframes`
   0%, 100% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-100%);
  }
  77% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-200%);
  }
`;

const NavButton = styled.button`
  font-size: ${typographySizes.s}rem;
  margin: 0 20rem;
  margin-bottom: 20rem;
  padding: 0 30rem;
  line-height: 34rem;
  height: 37rem;
  color: ${colors.dark};
  border-radius: 37rem;
  border: solid 1rem ${colors.dark};
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  overflow: hidden;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  :hover {
    span {
      animation: ${move} 2s cubic-bezier(0.65, 0, 0.35, 1);
      color: inherit;
    }
  }
  &.active {
    color: ${colors.white};
    background-color: ${colors.dark};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    span {
      color: ${colors.white};
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 0 10rem;
    margin-bottom: 10rem;
  }
`;

const TilesWrapepr = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: repeat(7, 1fr);
  font-size: 10rem;
  height: 50vw;
  gap: 20rem 20rem;
  grid-template-areas:
    "img1 img2 img3"
    "img1 img2 img3"
    "img1 img2 img3"
    "img1 quote img3"
    "img1 quote img4"
    "img1 quote img4"
    "img1 quote img4";
  @media (max-width: ${mediaQuery.tablet}) {
    height: initial;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 60vw);
    gap: 15rem 15rem;
    grid-template-areas:
      "img1 img2"
      "img3 img4"
      "quote quote";
  }
`;

const StyledImg = styled(Img)`
  display: none;
  height: 100%;
  border-radius: 8rem;
  &.show {
    display: block;
  }
`;

const Img1 = styled.div`
  > div {
    height: 100%;
  }
  grid-area: img1;

  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  &.hide {
    opacity: 0;
    transform: translateY(10rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;
const Img2 = styled.div`
  grid-area: img2;
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  > div {
    height: 100%;
  }
  &.hide {
    opacity: 0;
    transform: translateY(10rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const Img3 = styled.div`
  grid-area: img3;
  overflow: hidden;
  border-radius: 8rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  > div {
    height: 100%;
  }
  &.hide {
    opacity: 0;
    transform: translateY(10rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const Img4 = styled.div`
  > div {
    height: 100%;
  }
  grid-area: img4;
  overflow: hidden;
  border-radius: 8rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  &.hide {
    opacity: 0;
    transform: translateY(10rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const Quote = styled.div`
  grid-area: quote;
  > div {
    height: 100%;
  }
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  position: relative;
  height: 100%;
  &.hide {
    opacity: 0;
    transform: translateY(10rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const QuoteIn = styled.div`
  background-color: ${(props) => props.color};

  border-radius: 8rem;
  padding: 25rem;
  height: calc(100% - 50rem);
  width: calc(100% - 50rem);
  display: none;
  justify-content: space-between;
  flex-direction: column;
  &.show {
    display: flex;
  }
`;

const QuoteText = styled.h5`
  font-size: 1.7vw;
  color: ${(props) => props.color};
  width: 80%;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 6vw;
  }
`;

const QuotePerson = styled.h6`
  font-size: ${typographySizes.s}rem;
  color: ${(props) => props.color};
`;

const GetInspired = ({ title, place }) => {
  const [hide1, setHide1] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [hide3, setHide3] = useState(false);
  const [hide4, setHide4] = useState(false);
  const [hide5, setHide5] = useState(false);
  const [curPos, setCurPos] = useState(0);
  const [curPosDelay1, setCurPosDelay1] = useState(0);
  const [curPosDelay2, setCurPosDelay2] = useState(0);
  const [curPosDelay3, setCurPosDelay3] = useState(0);
  const [curPosDelay4, setCurPosDelay4] = useState(0);
  const [curPosDelay5, setCurPosDelay5] = useState(0);

  const handleClick = (num) => {
    setCurPos(num);
    setHide1(true);
    setTimeout(function () {
      setCurPosDelay1(num);
      setHide1(false);
    }, 500);

    setTimeout(function () {
      setHide2(true);
    }, 100);
    setTimeout(function () {
      setCurPosDelay2(num);
      setHide2(false);
    }, 600);

    setTimeout(function () {
      setHide3(true);
    }, 200);
    setTimeout(function () {
      setCurPosDelay3(num);
      setHide3(false);
    }, 700);

    setTimeout(function () {
      setHide4(true);
    }, 300);
    setTimeout(function () {
      setCurPosDelay4(num);
      setHide4(false);
    }, 800);
    setTimeout(function () {
      setHide5(true);
    }, 400);
    setTimeout(function () {
      setCurPosDelay5(num);
      setHide5(false);
    }, 900);
  };

  let intViewportWidth = 0;
  const [mobile, setMobile] = useState(false);
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    intViewportWidth = window.innerWidth;
  }

  useEffect(() => {
    if (isBrowser) {
      if (isBrowser && intViewportWidth < 1024) {
        setMobile(true);
      }
    }
  }, []);
  return (
    <StyledMainWrapper id="get-inspired">
      <StyledTitle
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        {title}
      </StyledTitle>
      <Nav
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-delay="200"
        data-sal-duration="500"
      >
        {place.map((placeInfo, index) => (
          <NavButton
            className={curPos === index && "active"}
            onClick={() => handleClick(index)}
          >
            <span> {placeInfo.Name}</span>
            <span> {placeInfo.Name}</span>
          </NavButton>
        ))}
      </Nav>
      <TilesWrapepr>
        <Img1 className={hide1 && "hide"}>
          <Tilt
            tiltMaxAngleX="2"
            tiltMaxAngleY="2"
            reset={true}
            tiltReverse={true}
            tiltEnable={!mobile}
          >
            {place.map((placeInfo, index) => (
              <StyledImg
                objectFit="cover"
                className={curPosDelay1 === index && "show"}
                fluid={placeInfo.Img_1.localFile.childImageSharp.fluid}
              />
            ))}
          </Tilt>
        </Img1>
        <Img2 className={hide2 && "hide"}>
          <Tilt
            tiltMaxAngleX="4"
            tiltMaxAngleY="4"
            reset={true}
            tiltReverse={true}
            tiltEnable={!mobile}
          >
            {place.map((placeInfo, index) => (
              <StyledImg
                objectFit="cover"
                className={curPosDelay2 === index && "show"}
                fluid={placeInfo.Img_2.localFile.childImageSharp.fluid}
              />
            ))}
          </Tilt>
        </Img2>
        <Img3 className={hide3 && "hide"}>
          <Tilt
            tiltMaxAngleX="4"
            tiltMaxAngleY="4"
            reset={true}
            tiltReverse={true}
            tiltEnable={!mobile}
          >
            {place.map((placeInfo, index) => (
              <StyledImg
                objectFit="cover"
                className={curPosDelay3 === index && "show"}
                fluid={placeInfo.Img_3.localFile.childImageSharp.fluid}
              />
            ))}
          </Tilt>
        </Img3>
        <Img4 className={hide4 && "hide"}>
          <Tilt
            tiltMaxAngleX="4"
            tiltMaxAngleY="4"
            reset={true}
            tiltReverse={true}
            tiltEnable={!mobile}
          >
            {place.map((placeInfo, index) => (
              <StyledImg
                objectFit="cover"
                className={curPosDelay4 === index && "show"}
                fluid={placeInfo.Img_4.localFile.childImageSharp.fluid}
              />
            ))}
          </Tilt>
        </Img4>
        <Quote className={hide5 && "hide"}>
          <Tilt
            tiltMaxAngleX="4"
            tiltMaxAngleY="4"
            reset={true}
            tiltReverse={true}
            tiltEnable={!mobile}
          >
            {place.map((placeInfo, index) => (
              <QuoteIn
                color={placeInfo.Background_color}
                className={curPosDelay5 === index && "show"}
              >
                <QuoteText color={placeInfo.Text_color}>
                  {noHangers(placeInfo.Quote)}
                </QuoteText>
                <QuotePerson color={placeInfo.Text_color}>
                  {noHangers(placeInfo.Quote_person)}
                </QuotePerson>
              </QuoteIn>
            ))}
          </Tilt>
        </Quote>
      </TilesWrapepr>
    </StyledMainWrapper>
  );
};

export default GetInspired;
