import React from "react";
import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import noHangers from "src/assets/fn/noHangers.js";

import colors from "src/assets/styles/colors.js";

const Wrapper = styled.div`
  height: 100%;
  position: relative;

  border-radius: 8rem;
  z-index: 200;
  overflow: hidden;
  &.pos-1 {
    top: 40%;
    left: 0;
  }
  &.pos-2 {
    top: 20%;
    right: 8vw;
  }
  &.pos-3 {
    top: 80%;
    right: 25%;
  }
  &.mobile {
    display: none;
  }
  display: block;
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
    &.pos-1 {
      top: initial;
      left: initial;
      right: initial;
    }
    &.pos-2 {
      top: initial;
      left: initial;
      right: initial;
    }
    &.pos-3 {
      top: initial;
      left: initial;
      right: initial;
    }
    &.mobile {
      display: block;
    }
  }
`;

const Box = styled.div`
  background-color: ${colors.white};

  padding: 25rem;
  max-width: 250rem;
  height: 100%;
  @media (max-width: ${mediaQuery.laptop}) {
    max-width: 100vw;
  }
`;

const Title = styled.h3`
  font-size: ${typographySizes.sm}rem;
  font-weight: 300;
  margin-bottom: 10rem;
  margin-top: 15rem;
`;

const Paragraph = styled.p`
  font-size: ${typographySizes.xs}rem;
  font-weight: 100;
  line-height: 1.4;
`;

const Num = styled(Paragraph)`
  color: #7c7c7c;
`;

const Tile = ({ num, description, title }) => {
  return (
    <>
      <Wrapper className={`pos-${num} mobile`}>
        <Box>
          <Num>0{num}</Num>
          <Title>{noHangers(title)}</Title>
          <Paragraph>{noHangers(description)}</Paragraph>
        </Box>
      </Wrapper>
    </>
  );
};

export default Tile;
