import React from "react";
import styled, { keyframes } from "styled-components";
import colors from "src/assets/styles/colors.js";
import Arrow from "src/assets/svg/features-arrow.inline.svg";

const move = keyframes`
  0% {
    transform: translateX(0) ;
  }

  50% {
    transform: translateX(25rem);
  }
  51% {
    transform: translateX(-25rem);
  }
  100% {
    transform: translateX(0);
  }
`;
const StyledArrow = styled(Arrow)``;
const Wrapper = styled.div`
  height: 40rem;
  width: 40rem;
  background-color: ${colors.white};
  border-radius: 40rem;
  border: solid 1rem ${colors.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  &.left {
    transform: rotate(180deg);
  }
  :hover {
    ${StyledArrow} {
      animation: ${move} 0.5s;
    }
  }
`;

const ArrowSlider = ({ left, fnClick }) => {
  return (
    <Wrapper onClick={() => fnClick(1)} className={left && "left"}>
      <StyledArrow />
    </Wrapper>
  );
};

export default ArrowSlider;
