import React, { useState } from "react";
import Img from "gatsby-image";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import typographySizes from "src/assets/styles/typographySizes.js";

import distances from "src/assets/styles/distances.js";
import Title from "src/components/Typography/Title.js";
import Body from "src/components/Typography/Body.js";
import ArrowSlider from "src/components/Variant/InfoBox/ArrowSlider.js";

const Wrapper = styled.div`
  grid-area: productList;
  background-color: #363636;
  overflow: hidden;
  border-radius: 8rem;
  padding: 50rem;
  filter: drop-shadow(0rem 0rem 20rem rgba(0, 0, 0, 0.08));
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 35rem 15rem;
  }
  opacity: 0;
  transform: translateY(20rem);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.show {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.5s 0.9s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const SectionTitle = styled.h3`
  font-size: ${typographySizes.sm}rem;
  margin-bottom: 20rem;
  font-weight: 300;
  color: ${colors.white};
`;

const ListWrapper = styled.ul`
  width: 70%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 100%;
  }
`;
const Pos = styled.li`
  width: calc(50% - 50rem);
  padding-left: 20rem;
  color: ${colors.white};
  margin-right: 20rem;
  font-size: ${typographySizes.s}rem;
  position: relative;
  line-height: 1.4;
  margin-bottom: 10rem;
  :nth-last-of-type(2),
  :nth-last-of-type(1) {
    margin-bottom: 0;
  }
  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 4rem;
    height: 11rem;
    width: 11rem;
    border-radius: 11rem;
    background-color: ${(props) => props.color};
  }
  ::after {
    content: "";
    position: absolute;
    left: 3rem;
    top: 7rem;
    height: 5rem;
    width: 5rem;
    border-radius: 11rem;
    background-color: #363636;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(100% - 30rem);
    margin-right: 0rem;
    :nth-last-of-type(2) {
      margin-bottom: 10rem;
    }
  }
`;

const ProductList = ({ productList, show }) => {
  return (
    <Wrapper className={show && "show"}>
      <SectionTitle>spis produktów:</SectionTitle>
      <ListWrapper>
        {productList.map((pos) => (
          <Pos color={pos.Color_hex}>{pos.Name}</Pos>
        ))}
      </ListWrapper>
    </Wrapper>
  );
};

export default ProductList;
