import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import typographySizes from "src/assets/styles/typographySizes.js";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import Div100vh from "react-div-100vh";

import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import Ue from "src/assets/images/ue.png";

import ButtonSmallEx from "src/components/Global/ButtonSmallEx.js";
import LangButton from "src/components/Global/Nav/LangButton.js";

const Wrapper = styled.nav`
  height: 100rem;
  width: 100vw;
  /* background-color: ${colors.white}; */
  position: fixed;
  top: 0;
  left: 0;
  /* overflow: hidden; */
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  z-index: 9999;
  &.scrolled {
    height: 80rem;
    background-color: ${colors.white};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const StyledMainWrapper = styled(MainWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled(Img)`
  width: 160rem;
  margin-right: 20rem;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 115rem;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NavLinksWrapper = styled.div`
  display: flex;
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

const NavLink = styled(ScrollLink)`
  font-size: ${typographySizes.s}rem;
  text-decoration: none;
  padding: 5rem 15rem;
  font-weight: 100;
  position: relative;
  cursor: pointer;
  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 8rem;
    height: 11rem;
    width: 11rem;
    transform: scale(0);
    border-radius: 11rem;
    background-color: #ed5f5b;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ::after {
    content: "";
    position: absolute;
    left: 3rem;
    top: 11rem;
    height: 5rem;
    width: 5rem;
    transform: scale(0);
    border-radius: 11rem;
    background-color: #fff;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.active {
    ::before {
      transform: scale(1);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ::after {
      transform: scale(1);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
  :hover::before {
    transform: scale(1);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: ${typographySizes.m}rem;
    font-weight: 500;
    margin-top: 10rem;
    padding-left: 20rem;
    ::before {
      top: 20rem;
    }
    ::after {
      top: 23rem;
    }
  }
`;
const ButtonsWrapper = styled.div`
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  cursor: pointer;
  .ue {
    display: block;
    width: 30rem;
    height: 20rem;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    .ue {
      display: none;
    }
  }
`;

const MobileUe = styled(Ue)`
  display: none;
`;

const Hamburger = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
  padding: 6rem;
  border: solid 1rem ${colors.dark};
  border-radius: 100rem;
  @media (max-width: ${mediaQuery.laptop}) {
    display: flex;
  }
  .line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 7;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 7;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 7;
  }

  &.opened {
    .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }
    .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 6;
    }
    .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }
  }
`;

const MobileNav = styled(Div100vh)`
  background-color: ${colors.white};

  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  display: none;
  @media (max-width: ${mediaQuery.laptop}) {
    display: block;
  }
  &.opened {
    transform: translateX(0);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ${NavLinksWrapper} {
    display: flex;
    flex-direction: column;
    margin-top: 130rem;
  }
  ${ButtonsWrapper} {
    display: flex;
    margin-bottom: 30rem;
  }
  ${MainWrapper} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const MobileBottom = styled.div``;

const ImgEuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ue {
    width: 50rem;
  }
`;

const ImgMobile = styled(Img)`
  width: 80%;
  position: relative;
  left: 15rem;
`;

const Nav = ({
  logo,
  variants,
  features,
  inspirations,
  buyLink,
  buyName,
  labelCreatorLink,
  labelCreatorName,
  lang,
  imgMobile,
}) => {
  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]');
  }
  const isBrowser = typeof window !== "undefined";
  const [navScrolled, setNavScrolled] = useState(false);
  const [opened, setOpened] = useState(false);
  const handleClick = () => {
    setOpened(opened ? false : true);
  };
  useEffect(() => {
    if (isBrowser) {
      window.onscroll = function (e) {
        let scrollTop =
          window.pageYOffset ||
          (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;

        scrollTop > 150 ? setNavScrolled(true) : setNavScrolled(false);
      };
    }
  }, []);
  return (
    <Wrapper className={navScrolled && "scrolled"}>
      <MobileNav className={opened && "opened"}>
        <MainWrapper>
          <NavLinksWrapper>
            <NavLink
              onClick={handleClick}
              to="feature"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >
              {features}{" "}
            </NavLink>
            <NavLink
              onClick={handleClick}
              to="variants"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >
              {variants}
            </NavLink>
            <NavLink
              onClick={handleClick}
              to="get-inspired"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >
              {inspirations}{" "}
            </NavLink>
          </NavLinksWrapper>
          <MobileBottom>
            <ButtonsWrapper>
              <ButtonSmallEx href={labelCreatorLink}>
                {labelCreatorName}
              </ButtonSmallEx>
              <ButtonSmallEx href={buyLink} sec>
                {buyName}
              </ButtonSmallEx>
            </ButtonsWrapper>
            <ImgEuWrapper>
              <img className="ue" src={Ue} />
              <ImgMobile fluid={imgMobile} />
            </ImgEuWrapper>
          </MobileBottom>
        </MainWrapper>
      </MobileNav>
      <StyledMainWrapper>
        <LogoWrapper>
          <ScrollLink
            to="top"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <Logo
              loading="eager"
              fluid={logo}
              objectFit="contain"
              objectPosition="50% 50%"
            />
          </ScrollLink>
          <img className="ue" src={Ue} />
        </LogoWrapper>

        <RightWrapper>
          <NavLinksWrapper>
            <NavLink
              to="feature"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >
              {features}{" "}
            </NavLink>
            <NavLink
              to="variants"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >
              {variants}
            </NavLink>
            <NavLink
              to="get-inspired"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >
              {inspirations}{" "}
            </NavLink>
          </NavLinksWrapper>
          <LangButton lang={lang} />
          <ButtonsWrapper>
            <ButtonSmallEx href={labelCreatorLink}>
              {" "}
              {labelCreatorName}
            </ButtonSmallEx>
            <ButtonSmallEx href={buyLink} sec>
              {buyName}
            </ButtonSmallEx>
          </ButtonsWrapper>
          <Hamburger onClick={handleClick} className={opened && "opened"}>
            <svg width="30" height="30" viewBox="0 0 100 100">
              <path
                class="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path class="line line2" d="M 20,50 H 80" />
              <path
                class="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </Hamburger>
        </RightWrapper>
      </StyledMainWrapper>
    </Wrapper>
  );
};

export default Nav;
