const typographySizes = {
  xs: 12,
  s: 15,
  sm: 20,
  mM: 28,
  m: 36,
  l: 42,
  xl: 86,
};

export default typographySizes;
