import React from "react";

import styled, { keyframes } from "styled-components";

import colors from "src/assets/styles/colors.js";
import typographySizes from "src/assets/styles/typographySizes.js";

const move = keyframes`
   0%, 100% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-100%);
  }
  77% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-200%);
  }
`;

const ButtonWrapper = styled.a`
  display: inline-block;
  text-decoration: none;
  position: relative;
  height: 30rem;
  text-align: center;
  font-size: ${typographySizes.xs}rem;
  line-height: 30rem;
  font-weight: 300;
  color: ${colors.dark};
  border: solid 1rem ${colors.dark};
  border-radius: 50rem;
  padding: 0 25rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  &.sec {
    background-color: ${colors.dark};
    color: ${colors.white};
    margin-left: 15rem;
    span {
      color: inherit;
    }
  }
  :hover {
    span {
      animation: ${move} 2s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const ButtonSmallEx = ({ children, sec, href }) => (
  <ButtonWrapper href={href} target="_blank" className={sec && "sec"}>
    <span>{children}</span>
    <span> {children}</span>
  </ButtonWrapper>
);

export default ButtonSmallEx;
