import React from "react";
import styled, { keyframes } from "styled-components";
import Arrow from "src/assets/svg/features-arrow.inline.svg";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const StyledArrow = styled(Arrow)``;

const move = keyframes`
  0% {
    transform: translateX(0) ;
  }

  50% {
    transform: translateX(40rem);
  }
  51% {
    transform: translateX(-40rem);
  }
  100% {
    transform: translateX(0);
  }
`;

const Btn = styled.div`
  width: 55rem;
  height: 55rem;
  border: solid 1rem ${colors.dark};
  border-radius: 55rem;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-left: 100rem;
  :hover {
    ${StyledArrow} {
      animation: ${move} 0.7s;
    }
  }
  &.left {
    margin-left: 0;
    margin-right: 100rem;
    transform: translateX(0rem) rotate(180deg);
    :hover {
      ${StyledArrow} {
        animation: ${move} 0.7s;
      }
    }
  }

  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

const ArrowButton = ({ left }) => {
  return (
    <Btn className={left && "left"}>
      {" "}
      <StyledArrow />{" "}
    </Btn>
  );
};

export default ArrowButton;
