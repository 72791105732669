import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import "swiper/css";
import MainWrapper from "src/components/global/MainWrapper.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import distances from "src/assets/styles/distances.js";
import CloseX from "src/assets/svg/close-variants.inline.svg";
import Title from "src/components/Typography/Title.js";
import VariatnThumbnail from "src/components/Variant/VariatnThumbnail.js";
import ArrowButton from "src/components/Variant/ArrowButton.js";
import InfoBox from "src/components/Variant/InfoBox/InfoBox.js";
import noHangers from "src/assets/fn/noHangers.js";

const BgWrapper = styled.div`
  background-color: ${colors.grey};
  /* margin-top: 100rem; */
  margin-bottom: 100rem;
  overflow: hidden;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-bottom: 40rem;
  }
`;
const StyledWrapper = styled(MainWrapper)`
  padding: 100rem 0;
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 0rem 0 40rem;
  }
`;

const TopRow = styled.div`
  margin-bottom: 40rem;
  display: flex;
  justify-content: center;
`;

const VariantsWrapper = styled.div`
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  left: ${distances.pageMarginBig * -1}rem;
  width: calc(100% + ${distances.pageMarginBig * 2}rem);
  transform: translateY(0rem);
  /* background-color: red; */
  max-height: 40vw;

  .swiper {
    overflow: visible !important;
  }

  .swiper-slide {
    &:first-of-type {
      margin-left: ${distances.pageMarginBig}rem;
    }
  }

  &.hide {
    opacity: 0;
    /* transform: translateY(40rem); */
    max-height: 0rem;
    /* display: none; */
    transition: all 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.laptop}) {
    max-height: 140vw;
  }
`;

const StyledTitle = styled(Title)`
  margin-top: 5rem;
  width: 350rem;
  text-align: center;
`;

const VariantWrpper = styled.div`
  display: flex;
  width: 100%;
`;

const ArrowWrapper = styled.div`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  &.hide {
    opacity: 0;
    transform: translateY(20rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;
const VariantNav = styled.div`
  position: sticky;
  top: 120rem;
  justify-content: space-between;
  width: 100%;
  display: flex;

  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;
const VariantNavWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  /* > div {
    width: 200rem;
  } */
  @media (max-width: ${mediaQuery.laptop}) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const NavThumbWrapper = styled.div`
  width: 300rem;
  margin-top: 90rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  z-index: 999;

  &:first-of-type {
    position: relative;
    left: -250rem;
    :hover {
      transform: translateX(130rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
  &:last-of-type {
    position: absolute;
    right: -250rem;
    top: 0;
    :hover {
      transform: translateX(-130rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

const OpenModalWrapper = styled.div`
  position: relative;

  max-height: 0vw;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.65, 0, 0.35, 1);
  &.show {
    max-height: 600vw;
    opacity: 1;
    transition: all 0.9s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const CloseButton = styled.button`
  height: 40rem;
  width: 40rem;
  border-radius: 40rem;
  position: absolute;
  top: 0;
  right: 0;
  border: solid 1rem ${colors.dark};
  cursor: pointer;
  :hover {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    transform: rotate(180deg);
  }
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
  &.mobile {
    display: none;
    @media (max-width: ${mediaQuery.laptop}) {
      display: block;
      z-index: 1000;
      position: sticky;
      top: 100rem;
      margin-top: -10rem;
      margin-right: -10rem;
      background-color: ${colors.white};

      right: 0;
    }
  }
`;
const ControlWrapper = styled.div`
  display: flex;
  /* background-color: red; */

  width: 650rem;
  position: absolute;

  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: 30rem; */
  top: -90rem;
  z-index: 100;
  opacity: 1;
  transition: all 0.3s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.hide {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;
const LeftCon = styled.div``;
const Controls = ({ hide }) => {
  const swiper = useSwiper();

  return (
    <ControlWrapper className={hide && "hide"}>
      <LeftCon onClick={() => swiper.slidePrev()}>
        <ArrowButton left />
      </LeftCon>
      <LeftCon onClick={() => swiper.slideNext()}>
        <ArrowButton />
      </LeftCon>
    </ControlWrapper>
  );
};
const Variants = ({ variants, title, firstCol, secondCol }) => {
  const [curent, setCurent] = useState(0);
  const [next, setNext] = useState(1);
  const [prev, setPrev] = useState(variants.length - 1);
  const [hideSlider, setHideSlider] = useState(false);
  const [slidesCount, seSlidesCount] = useState(4.4);

  const swiper = useSwiper();

  let intViewportWidth = 0;
  const isBrowser = typeof window !== "undefined";
  useEffect(() => {
    if (isBrowser) {
      intViewportWidth = window.innerWidth;
    }
    if (isBrowser && intViewportWidth < 768) {
      seSlidesCount(1.9);
    }
  }, []);

  const handleClick = (num) => {
    setCurent(num);
    if (num >= variants.length - 1) {
      setNext(0);
    } else {
      setNext(num + 1);
    }
    if (num <= 0) {
      setPrev(variants.length - 1);
    } else {
      setPrev(num + -1);
    }
    setHideSlider(true);
  };
  const handleClose = () => {
    setHideSlider(false);
  };
  return (
    <BgWrapper id="variants">
      <StyledWrapper>
        <TopRow>
          <ArrowWrapper
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-delay="200"
            data-sal-duration="500"
            className={hideSlider && "hide"}
          ></ArrowWrapper>

          <StyledTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            {noHangers(title)}
          </StyledTitle>
          <ArrowWrapper
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-delay="200"
            data-sal-duration="500"
            className={hideSlider && "hide"}
          >
            {" "}
            {/* <ArrowButton onClick={() => swiper.slideNext()} /> */}
          </ArrowWrapper>
        </TopRow>
        <OpenModalWrapper className={hideSlider && "show"}>
          <VariantNavWrapper>
            <CloseButton className="mobile">
              <CloseX onClick={() => handleClose()} />
            </CloseButton>
            {/* <VariantNav> */}
            <CloseButton>
              <CloseX onClick={() => handleClose()} />
            </CloseButton>
            <NavThumbWrapper onClick={() => handleClick(prev)}>
              <VariatnThumbnail sizeS variant={variants[prev]} />
            </NavThumbWrapper>
            <NavThumbWrapper onClick={() => handleClick(next)}>
              <VariatnThumbnail sizeS variant={variants[next]} />
            </NavThumbWrapper>
            {/* </VariantNav> */}
          </VariantNavWrapper>

          <InfoBox
            firstCol={firstCol}
            secondCol={secondCol}
            show={hideSlider}
            info={variants[curent].node}
          />
        </OpenModalWrapper>

        <VariantsWrapper className={hideSlider && "hide"}>
          <Swiper
            spaceBetween={15}
            slidesPerView={slidesCount}
            className="mySwiper"
            grabCursor={true}
          >
            <Controls hide={hideSlider} />

            {variants.map((variant, index) => (
              <SwiperSlide>
                <VariantWrpper onClick={() => handleClick(index)}>
                  <VariatnThumbnail variant={variant} />
                </VariantWrpper>
              </SwiperSlide>
            ))}
            <SwiperSlide></SwiperSlide>
          </Swiper>
        </VariantsWrapper>
      </StyledWrapper>
    </BgWrapper>
  );
};

export default Variants;
