import React from "react";
import styled from "styled-components";
import MainWrapper from "src/components/global/MainWrapper.js";
import Img from "gatsby-image";

import colors from "src/assets/styles/colors.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Button from "src/components/Global/ButtonEx.js";
import distances from "src/assets/styles/distances.js";
import noHangers from "src/assets/fn/noHangers.js";

import mediaQuery from "src/assets/styles/mediaQuery.js";
import Body from "src/components/Typography/Body.js";

const Wrapper = styled.footer`
  padding-top: 100rem;
  background-color: ${colors.grey};
  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 40rem;
  }
`;

const StyledWrapper = styled(MainWrapper)`
  display: grid;

  grid-template-columns: 8.33% 58.33% 8.33% 25%;
  grid-template-rows: auto;
  font-size: 10rem;
  grid-template-areas:
    ". mainInfo . logo"
    ". mainInfo . img "
    ". subInfo . img";
  @media (max-width: ${mediaQuery.desktop}) {
    grid-template-columns: 66.66% 8.33% 25%;
    grid-template-areas:
      " mainInfo . logo"
      " mainInfo . img "
      " subInfo . img";
  }
  @media (max-width: ${mediaQuery.tablet}) {
    grid-template-columns: 50% 50%;
    grid-template-areas:
      "mainInfo mainInfo"
      "logo subInfo"
      "img img";
  }
`;

const MainInfo = styled.div`
  grid-area: mainInfo;
`;

const Title = styled.h3`
  color: ${colors.dark};
  font-size: ${typographySizes.m}rem;
  margin-bottom: 20rem;
`;

const SubTitle = styled.h4`
  color: ${colors.dark};
  font-size: ${typographySizes.sm}rem;
  margin-bottom: 50rem;
  max-width: 350rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 40rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledButton = styled.div`
  margin-right: 40rem;
  margin-bottom: 40rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-right: 20rem;
    margin-bottom: 20rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const PrivacyPolicy = styled.a`
  color: ${colors.dark};
  font-size: ${typographySizes.s}rem;
  opacity: 0.6;
  margin-top: 60rem;
  display: inline-block;
  text-decoration: none;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 5rem;
  }
`;

const Logo = styled.div`
  grid-area: logo;
  width: 150rem;
  margin-top: 70rem;

  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 40rem;
    position: relative;
  }
`;

const SubInfo = styled.div`
  grid-area: subInfo;
  font-size: ${typographySizes.s}rem;
  margin-top: 40rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30rem;
  opacity: 0.6;
  .moxom {
    margin-left: 40rem;
    display: inline-block;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    margin-top: 40rem;

    .moxom {
      margin-left: 0rem;
    }
  }
`;

const We3 = styled(Body)`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 30rem;
  }
`;

const ImgWrapper = styled.div`
  grid-area: img;
  position: relative;
  align-self: end;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 90%;
  }
`;

const FooterImg = styled(Img)`
  width: calc(100% + ${distances.pageMarginBig}rem);
  margin-top: auto;
`;

const Footer = ({ title, subTitle, logo, img, links }) => {
  return (
    <Wrapper>
      <StyledWrapper>
        <MainInfo>
          <Body>
            <Title>{noHangers(title)}</Title>
            <SubTitle>{noHangers(subTitle)}</SubTitle>
            <ButtonsWrapper>
              {links.map((linkInfo) => (
                <StyledButton>
                  <Button href={linkInfo.Link}>{linkInfo.Name}</Button>
                </StyledButton>
              ))}
            </ButtonsWrapper>
            <PrivacyPolicy>Polityka prywatności</PrivacyPolicy>
          </Body>
        </MainInfo>

        <Logo>
          <Img
            objectFit="contain"
            fluid={logo.localFile.childImageSharp.fluid}
          />
        </Logo>

        <SubInfo>
          <Body>
            © 2022 by Moxom. All rights reserved!{" "}
            <a
              className="moxom"
              href="https://www.instagram.com/moxom_pl/"
              target="_blank"
            >
              moxom
            </a>
          </Body>
          <We3>
            Design & development:{" "}
            <a href="https://we3studio.pl/" target="_blank">
              we3studio
            </a>
          </We3>
        </SubInfo>
        <ImgWrapper>
          <FooterImg fluid={img.localFile.childImageSharp.fluid} />
        </ImgWrapper>
      </StyledWrapper>
    </Wrapper>
  );
};

export default Footer;
