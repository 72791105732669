import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import gsap from "gsap";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import "swiper/css";
import MainWrapper from "src/components/global/MainWrapper.js";
import Title from "src/components/Typography/Title.js";
import Body from "src/components/Typography/Body.js";
import ButtonEx from "src/components/Global/ButtonEx.js";
import ImgsStripe from "src/components/About/ImgsStripe.js";
import noHangers from "src/assets/fn/noHangers.js";

const Wrapper = styled.div`
  width: 100vw;

  background-color: #4f726e;
  position: relative;
  overflow: hidden;

  @media (max-width: ${mediaQuery.tablet}) {
    padding-bottom: 40rem;
  }
`;

const StyledWrapper = styled(MainWrapper)`
  display: flex;
  align-items: center;
  /* min-height: 100vh; */

  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const InfoRow = styled.div`
  width: 41.66%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 40rem;
    margin-bottom: 40rem;
  }
`;

const StyledTitle = styled(Title)`
  color: ${colors.white};
  max-width: 490rem;
`;

const StyledBody = styled(Body)`
  color: ${colors.white};
  margin-top: 20rem;
  margin-bottom: 90rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 40rem;
  }
`;

const StyledButton = styled(ButtonEx)`
  border-color: ${colors.white};
  color: ${colors.white};
`;

const ImgCol = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  height: 100vh;
  max-height: 780rem;
  position: relative;
  /* background-color: red; */
  overflow: hidden;
  margin-left: 8.33%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% + 30rem);
    left: 0rem;
    margin-left: 0;

    height: 400rem;
    flex-direction: column;
  }
`;

const About = ({
  title,
  paragraph,
  img1col,
  img2col,
  buttonName,
  buttonLink,
}) => {
  return (
    <Wrapper>
      <StyledWrapper>
        <InfoRow>
          <StyledTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            {noHangers(title)}
          </StyledTitle>
          <StyledBody
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-delay="200"
            data-sal-duration="500"
          >
            {noHangers(paragraph)}
          </StyledBody>
          <StyledButton white href={buttonLink}>
            {buttonName}
          </StyledButton>
        </InfoRow>
        <ImgCol>
          <ImgsStripe imgs={img1col} />
          <ImgsStripe sec imgs={img2col} />
        </ImgCol>
      </StyledWrapper>
    </Wrapper>
  );
};

export default About;
